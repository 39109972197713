import '../App.css'; // Import your App.css file
import Navbar from '../Navbar';
import { Link } from 'react-router-dom';

const AnnouncementsPage = () => {
  return (
    <div>
      <Navbar />
      <div className='main-content'>
        <div className='border-bottom blue-bg'>
          <div className='container text-center padding-top padding-bottom'>
            <div className='col-12-lg'>
              <h1>Announcements</h1>
              <p>
                Any updates to the information provided prior to November 8th
                will be updated below. We will also email you the most important
                updates. Once we are in Mexico, all updates and communication
                will be via our WhatsApp Community, so be sure to download the
                app before the trip!
              </p>
            </div>
          </div>
        </div>
        <div className='border-bottom'>
          <div className='container padding-top padding-bottom'>
            <div className='col-12-lg'>
              <h2>Booking Details</h2>
              <h3>Jan 11, 2025</h3>
              <p>
                Happy new year everyone! We have just booked our flight and
                resort, and wanted to pass along the information you may need
                whenever you plan to book.
              </p>
              <h3>Our Booking Details</h3>
              <p>
                <b>Flight: </b>YVR to CUN Air Canada flight 1314; Departs 8:10am
                Nov 8; Arrives 5:10pm Nov 8 <br />
                <b>Resort:</b> Occidental at Xcaret; Deluxe Room; Check-in Nov
                8; Check-out Nov 15; added request for room in building 18, 19,
                or 20.
              </p>
              <h3>Room Options</h3>
              <p>
                There are 4 tiers of rooms available at the resort:
                <br />
                <b>1. Deluxe</b> is the basic/standard accommodation. For
                7-nights stay you get 4 a-la-carte dinners.
                <br />
                <b>2. Deluxe Concierge:</b> same rooms as the Deluxe rooms, but
                they include nicer amenities (better brand of soaps), a small
                welcome package, and slippers and a bathrobe. <br />
                <b>3. Deluxe Plus:</b> same additions as Deluxe Concierge, and
                these rooms are located specifically in buildings 14-17
                surrounding a pool that is only accessible if you book this
                tier. It also includes a better in-room bar. <br />
                <b>4. Royal Level:</b> all royal-level rooms are located in the
                back area of the park. They are nicer rooms that include all of
                the top amenities. Also being royal level includes access to the
                pool and additional restaurant (that you can eat at on
                non-a-la-carte-nights instead of the buffet) in the royal level
                area, and an additional exclusive lounge.
                <br />
              </p>
              <img
                className='img'
                src='/img/resortmap.png'
                style={{ maxWidth: '500px', height: 'auto' }}
              ></img>
              <h3>Booking the Resort and Flights</h3>
              <p>
                If you have a travel agent/company that you like to use, feel
                free to look into their options. Otherwise, we've done some
                comparisons for the current options available online (as of Jan
                11):
              </p>
              <ul>
                <li>
                  Booking a flight + stay package through expedia is the
                  cheapest option we could find.{' '}
                  <b>
                    Note the first prices they show you will not be direct
                    flights.
                  </b>
                </li>
                <li>
                  Note that if you are interested in booking a Deluxe Plus room,
                  the only place we could find this option was booking through
                  the Barcelo directly. You also have the option of calling to
                  book through Barcelo on the phone, which they told us could
                  lead to better deals - if you want to go for Deluxe Plus or
                  Royal Level, it is probably worth looking into this option.{' '}
                </li>
                <li>
                  We ended up booking our resort stay on Hotels.com and the
                  flights directly through Air Canada, because we are staying in
                  the area for a few extra days but moving to a different hotel.
                </li>
              </ul>
              <p>
                If you have any other questions or want to talk to us about
                anything else before booking feel free to reach out!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementsPage;
